.doorRoad {
  display: flex;
  flex-direction: row;
}
.timeStyle {
  display: flex;
  flex-direction: row;
}
.addStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
